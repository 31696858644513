/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:3023e1e2-058a-4769-be00-523ee4881a47",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_PA8PBBfmw",
    "aws_user_pools_web_client_id": "5djhagru6vu9jo3hjipvbf4d93",
    "oauth": {
        "domain": "knora314ae1dc-314ae1dc-qa.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://www.knora-qa.hcie.io/callback,https://www.knora-qa.hcie.io/callback/,https://knora-qa.hcie.io/callback,https://knora-qa.hcie.io/callback/",
        "redirectSignOut": "https://www.knora-qa.hcie.io/,https://knora-qa.hcie.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "knora8ad3b0baf07c405abf39f7ef7846770e114948-qa",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://aplskkeqr5abdmnq2g32dpig3m.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
