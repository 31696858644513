// eslint-disable-next-line no-use-before-define
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'intersection-observer'
import 'wicg-inert'
import { UserContextProvider } from './src/UserContext'
import { Theme } from '@liquid-design/liquid-design-react'
import { NotificationsContextProvider } from './src/components/Notification/NotificationsContext'
import { initTracking } from 'utils/tracking'
import ExtractionModalWrapper from 'components/ExtractionModal/ExtractionModalWrapper'

initTracking()

export const wrapRootElement = ({ element }) => {
  return (
    <Theme className="max-h-full flex flex-col inset-0" themeName="richBlue">
      <UserContextProvider>
        <NotificationsContextProvider>
          {element}
          <ExtractionModalWrapper />
        </NotificationsContextProvider>
      </UserContextProvider>
    </Theme>
  )
}
