import React, { useEffect, useState } from 'react'
import { useNotifications } from 'components/Notification/NotificationsContext'
import { useStoreExtraction } from 'store/extraction'

const CompleteMessage = () => {
  const { getNotificationsRef } = useNotifications()

  const [hasStartedExtracting, setHasStartedExtracting] = useState<boolean>(
    false
  )
  const isExtracting = useStoreExtraction((state) => state.isExtracting)
  const hasExtractionErrors = useStoreExtraction((state) => state.hasErrors)
  const extractionProgress = useStoreExtraction(
    (state) => state.extractionProgress
  )
  useEffect(() => {
    if (hasStartedExtracting && !isExtracting) {
      setHasStartedExtracting(false)
      // Show message
      if (hasExtractionErrors) {
        getNotificationsRef().addNotification({
          text: 'Extraction completed with errors.',
          isInfo: true,
        })
      } else {
        getNotificationsRef().addNotification({
          text: 'Extraction complete.',
          color:
            window
              .getComputedStyle(document.documentElement)
              .getPropertyValue('--vibrant-green-dark') || undefined,
        })
      }
      return
    }
    if (isExtracting) {
      setHasStartedExtracting(true)
    }
  }, [
    hasStartedExtracting,
    isExtracting,
    extractionProgress,
    getNotificationsRef,
    hasExtractionErrors,
  ])

  return <></>
}

export default CompleteMessage
