import React from 'react'

import './RemoveButton.css'

interface Props {
  className?: string
  disabled?: boolean
  onClick: () => void
  title: string
  red?: boolean
  'data-test'?: string
}

const RemoveButton: React.FC<Props> = (props) => {
  const cn = `remove-button ${props.red ? 'remove-button--red' : ''} ${
    props.className || ''
  }`
  return (
    <button
      data-test={props['data-test']}
      className={cn}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <svg
        role="img"
        className="remove-button__icon"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{props.title}</title>
        <path
          className="remove-button__circle"
          d="M10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5C21 16.299 16.299 21 10.5 21ZM10.5 19C15.1944 19 19 15.1944 19 10.5C19 5.80558 15.1944 2 10.5 2C5.80558 2 2 5.80558 2 10.5C2 15.1944 5.80558 19 10.5 19Z"
        />
        <path
          className="remove-button__cross"
          d="M14 7L7 14"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="remove-button__cross"
          d="M14 14L7 7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  )
}

export default RemoveButton
