import React from 'react'
import { Link } from 'gatsby'
import {
  Button,
  Headline,
  LightBox,
  Theme,
} from '@liquid-design/liquid-design-react'
import Tippy from '@tippyjs/react'
import { Auth, Storage } from 'aws-amplify'
import { modalStyles } from 'utils/modalStyles'
import PDFInput from './PDFInput/PDFInput'
import PMIDInput from './PMIDInput/PMIDInput'
import ExtractionBox from './ExtractionBox/ExtractionBox'
import ExtractionResultHeadline from './ExtractionResultHeadline/ExtractionResultHeadline'
import CompleteMessage from './CompleteMessage/CompleteMessage'
import RemoveButton from 'components/RemoveButton/RemoveButton'
import RoundLoadingIndicator from 'components/RoundLoadingIndicator/RoundLoadingIndicator'
import PubMedIconComponent from 'assets/svgs/pubmed-small.svg'
import PdfIconComponent from 'assets/svgs/pdf.svg'
import CheckIconComponent from 'assets/svgs/check.svg'
import CrossIconComponent from 'assets/svgs/cross.svg'
import { getNotificationsRef } from 'components/Notification/NotificationsContext'
import { getExternalPublication } from 'services/publication'
import {
  subscribeToQueuedJob,
  queueExtractionJob,
  cancelExtractionJob,
} from 'services/extraction'
import { useStoreExtraction } from 'store/extraction'
import { PublicationSource } from 'types/amplify/sharedTypes/publication/enums/publicationSource'
import { JobStatus } from 'types/amplify/sharedTypes/publication/enums/jobStatus'
import { QueuedJob } from 'types/amplify/sharedTypes/publication/interfaces/queuedJob.interface'

import './ExtractionModal.css'

enum OverallExtractionStatus {
  Complete = 'Complete',
  Extracting = 'Extracting',
  Pending = 'Pending',
  Idle = 'Idle',
  None = 'None',
}

export enum ExtractionStatus {
  Added = 'Added',
  Uploading = 'Uploading',
  Pending = 'Pending',
  Extracting = 'Extracting',
  UploadError = 'UploadError',
  ExtractionError = 'ExtractionError',
  Done = 'Done',
}

interface ExtractionEntry {
  status: ExtractionStatus
  extractionProgress?: number
}

interface PubMedEntry extends ExtractionEntry {
  title: string
  pmid: string
}

export interface PDFEntry extends ExtractionEntry {
  file: File | { name: string; lastModified: string }
  errors?: {
    message: string
    code:
      | 'file-too-large'
      | 'file-too-small'
      | 'too-many-files'
      | 'file-invalid-type'
  }[]
  uploadProgress?: number
}

interface Props {
  isOpen: boolean
  isExtracting: boolean
  extractionProgress: number
  onClose?: () => void
  owner: string
}

interface State {
  pmidInputValue: string
  isProcessingPMID: boolean
  isCancelingExtraction: boolean
  pdfEntries: PDFEntry[]
  pubMedEntries: PubMedEntry[]
  totalToExtract: number
  totalExtracted: number
  extractionSessionId: string
  fakeProgressInterval: number | undefined
}

class ExtractionModal extends React.Component<Props, State> {
  state: State = {
    pmidInputValue: '',
    isProcessingPMID: false,
    isCancelingExtraction: false,
    pdfEntries: [],
    pubMedEntries: [],
    totalToExtract: 0,
    totalExtracted: 0,
    extractionSessionId: '',
    fakeProgressInterval: undefined,
  }
  pmidInputRef?: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.pmidInputRef = React.createRef()
  }

  updatePdfEntryState(
    entry: PDFEntry,
    status: ExtractionStatus,
    progress?: number
  ) {
    const index = this.state.pdfEntries.findIndex(
      (pdfEntry) => pdfEntry.file === entry.file
    )
    const updatedEntry = {
      file: entry.file,
      status,
      errors: entry.errors,
      uploadProgress: status === ExtractionStatus.Uploading ? progress : 1,
      extractionProgress:
        status === ExtractionStatus.Extracting ||
        status === ExtractionStatus.ExtractionError ||
        status === ExtractionStatus.Done
          ? progress
          : undefined,
    }
    this.setState({
      pdfEntries: [
        ...this.state.pdfEntries.slice(0, index),
        updatedEntry,
        ...this.state.pdfEntries.slice(index + 1),
      ],
    })
  }

  updatePubMedEntryState(
    entry: PubMedEntry,
    status: ExtractionStatus,
    progress?: number
  ) {
    const index = this.state.pubMedEntries.findIndex(
      (pubMedEntry) => pubMedEntry.pmid === entry.pmid
    )
    const updatedEntry = {
      pmid: entry.pmid,
      title: entry.title,
      status,
      extractionProgress:
        status === ExtractionStatus.Extracting ||
        status === ExtractionStatus.ExtractionError ||
        status === ExtractionStatus.Done
          ? progress
          : undefined,
    }
    this.setState({
      pubMedEntries: [
        ...this.state.pubMedEntries.slice(0, index),
        updatedEntry,
        ...this.state.pubMedEntries.slice(index + 1),
      ],
    })
  }

  updateEntryState(
    job: QueuedJob,
    status: ExtractionStatus,
    progress?: number
  ) {
    if (job.source === PublicationSource.Pdf) {
      const entry = this.state.pdfEntries.find(
        (e) => `__gc__${e.file.name}` === job.fileName
      )
      if (!entry) throw new Error('NO_MATCHING_ENTRY')
      this.updatePdfEntryState(entry, status, progress)
      return
    }
    if (job.source === PublicationSource.Pubmed) {
      const entry = this.state.pubMedEntries.find(
        (e) => e.pmid === job.pubmedId
      )
      if (!entry) throw new Error('NO_MATCHING_ENTRY')
      this.updatePubMedEntryState(entry, status, progress)
      return
    }
    throw new Error('INVALID_PUBLICATION_SOURCE')
  }

  async updatePubMedEntries(prevProps: Props, prevState: State) {
    // guard
    if (this.state.isProcessingPMID === prevState.isProcessingPMID) return

    // re-focus input field
    if (!this.state.isProcessingPMID) {
      this.pmidInputRef?.current?.querySelector('input')?.focus()
      return
    }

    // validate id
    if (!this.state.pmidInputValue) return

    // if already added, show a notification
    if (
      this.state.pubMedEntries.findIndex(
        (entry) => entry.pmid === this.state.pmidInputValue
      ) !== -1
    ) {
      getNotificationsRef().addNotification({
        text: 'Already added.',
        isInfo: true,
      })
      this.setState({
        isProcessingPMID: false,
      })
      return
    }

    try {
      const publication = await getExternalPublication(
        this.state.pmidInputValue
      )

      if (publication) {
        this.setState({
          // clear pmid input value on successfull fetch
          pmidInputValue: '',
          // add new item to list
          pubMedEntries: [
            {
              title: publication.title,
              pmid: publication.id,
              status: ExtractionStatus.Added,
            },
            ...this.state.pubMedEntries,
          ],
        })
      } else {
        getNotificationsRef().addNotification({
          text: `No publication found for PubMed ID ${this.state.pmidInputValue}.`,
          isError: true,
        })
      }
    } catch (err) {
      const errorMessage = `Failed fetching publication with PubMed ID ${this.state.pmidInputValue}.`
      console.error(errorMessage, err)
      getNotificationsRef().addNotification({
        text: errorMessage,
        isError: true,
      })
    }

    this.setState({
      isProcessingPMID: false,
    })
  }

  updatePdfEntries(prevProps: Props, prevState: State) {
    // Effect for uploading PDF entries
    this.state.pdfEntries.forEach((entry, index) => {
      if (entry.status === ExtractionStatus.Added) {
        // Change status to uploading
        this.updatePdfEntryState(entry, ExtractionStatus.Uploading)
      }
      if (
        prevState.pdfEntries[index] &&
        prevState.pdfEntries[index].status === ExtractionStatus.Added &&
        entry.status === ExtractionStatus.Uploading
      ) {
        // Upload
        // Prefix files with __gc__ for server side garbage collection
        Storage.put(`__gc__${entry.file.name}`, entry.file, {
          level: 'private',
          contentType: 'application/pdf',
          contentDisposition: 'inline',
          progressCallback: (progress: {
            loaded: number
            total: number
            key?: string
          }) => {
            this.updatePdfEntryState(
              entry,
              ExtractionStatus.Uploading,
              progress.loaded / progress.total
            )
          },
        })
          .then((result) => {
            this.updatePdfEntryState(entry, ExtractionStatus.Pending)
          })
          .catch((err) => console.error(err))
      }
    })
  }

  getJobs(identityId: string, extractionStates: ExtractionStatus[]) {
    return [
      ...this.state.pubMedEntries
        .filter((entry) => extractionStates.includes(entry.status))
        .map((entry) => ({
          pubmedId: entry.pmid,
          source: PublicationSource.Pubmed,
          entry,
        })),
      ...this.state.pdfEntries
        .filter((entry) => extractionStates.includes(entry.status))
        .map((entry) => ({
          fileName: `__gc__${entry.file.name}`,
          fileKey: `private/${identityId}/__gc__${entry.file.name}`,
          source: PublicationSource.Pdf,
          entry,
        })),
    ]
  }

  async startExtraction() {
    useStoreExtraction.setState({
      isExtracting: true,
      hasErrors: false,
      extractionProgress: 0,
    })

    const creds = await Auth.currentUserCredentials()
    const jobs = this.getJobs(creds.identityId, [
      ExtractionStatus.Added,
      ExtractionStatus.Pending,
    ])

    this.setState({ totalExtracted: 0, totalToExtract: jobs.length })
    jobs.forEach((job) => {
      const { entry, ...queuedJob } = job
      this.updateEntryState(
        queuedJob as QueuedJob,
        ExtractionStatus.Extracting,
        0
      )
    })

    let extractionSessionId
    try {
      extractionSessionId = await queueExtractionJob({
        jobs: jobs.map((job) => {
          const { entry, ...queuedJob } = job
          return queuedJob
        }),
      })
    } catch (err) {
      const errorMessage = 'Failed initializing extraction.'
      console.error(errorMessage, err)
      getNotificationsRef().addNotification({
        text: errorMessage,
        isError: true,
      })

      jobs.forEach((job) => {
        const { entry, ...queuedJob } = job
        this.updateEntryState(
          queuedJob as QueuedJob,
          job.source === PublicationSource.Pdf
            ? ExtractionStatus.Pending
            : ExtractionStatus.Added,
          0
        )
      })
      return
    }

    this.setState({
      extractionSessionId,
    })
    const incrementProgress = (hasErrors = false) => {
      const totalExtracted = this.state.totalExtracted + 1
      this.setState({ totalExtracted })
      const isExtracting = totalExtracted < this.state.totalToExtract
      useStoreExtraction.setState({
        isExtracting,
      })
      if (hasErrors) {
        useStoreExtraction.setState({
          hasErrors,
        })
      }
      if (!isExtracting) {
        this.setState({
          extractionSessionId: '',
        })
      }
    }

    // Fake extraction progress
    const tickDuration = 100
    this.setState({
      fakeProgressInterval: window.setInterval(() => {
        const jobs = this.getJobs(creds.identityId, [
          ExtractionStatus.Extracting,
          ExtractionStatus.ExtractionError,
          ExtractionStatus.Done,
        ])
        if (!jobs.length) return

        jobs.forEach((job) => {
          const { entry, ...queuedJob } = job
          if (
            job.entry.status === ExtractionStatus.ExtractionError ||
            job.entry.status === ExtractionStatus.Done
          ) {
            this.updateEntryState(queuedJob as QueuedJob, job.entry.status, 1)
          } else {
            const totalTime = 30e3
            const targetProgressUntilStop = 0.98
            const currentProgress = entry.extractionProgress || 0
            if (currentProgress < targetProgressUntilStop) {
              const randomProgressPerTick =
                // multiply a random value between 0 and 2
                (Math.random() *
                  2 *
                  // with a value that reduces progress speed with increased progress
                  Math.max(0, targetProgressUntilStop - currentProgress)) /
                // and devide that by total ticks
                (totalTime / tickDuration)
              this.updateEntryState(
                queuedJob as QueuedJob,
                ExtractionStatus.Extracting,
                currentProgress + randomProgressPerTick
              )
            }
          }
        })

        useStoreExtraction.setState({
          extractionProgress:
            jobs
              .map((job) => job.entry.extractionProgress || 0)
              .reduce((accumulator, progress) => accumulator + progress) /
            this.state.totalToExtract,
        })
      }, tickDuration),
    })

    await subscribeToQueuedJob(
      { sessionId: extractionSessionId },
      (queuedJob?: QueuedJob, err?: Error) => {
        if (err) {
          console.error('Extraction status unclear', err)
          if (queuedJob) {
            this.updateEntryState(
              queuedJob,
              ExtractionStatus.ExtractionError,
              1
            )
            this.setState({ totalToExtract: this.state.totalToExtract - 1 })
          }
          return
        }
        switch (queuedJob?.status) {
          case JobStatus.Cancelled:
          case JobStatus.Failed:
            this.updateEntryState(
              queuedJob,
              ExtractionStatus.ExtractionError,
              1
            )
            incrementProgress(true)
            break
          case JobStatus.Success:
            this.updateEntryState(queuedJob, ExtractionStatus.Done, 1)
            incrementProgress()
        }
      }
    )
  }

  async cancelExtraction() {
    this.setState({
      isCancelingExtraction: true,
    })
    try {
      await cancelExtractionJob({ sessionId: this.state.extractionSessionId })
      window.clearInterval(this.state.fakeProgressInterval)
    } catch (err) {
      const errorMessage = 'Failed canceling extraction.'
      console.error(errorMessage, err)
      getNotificationsRef().addNotification({
        text: errorMessage,
        isError: true,
      })
      this.setState({
        isCancelingExtraction: false,
      })
      return
    }

    useStoreExtraction.setState({
      isExtracting: false,
      extractionProgress: 0,
    })
    this.setState({
      isCancelingExtraction: false,
      extractionSessionId: '',
    })

    // Keep all extractions which are done; reset all idle, ongoing and failed ones.
    this.state.pubMedEntries
      .filter((entry) => entry.status !== ExtractionStatus.Done)
      .forEach((entry) => {
        this.updatePubMedEntryState(entry, ExtractionStatus.Added, 0)
      })
    this.state.pdfEntries
      .filter((entry) => entry.status !== ExtractionStatus.Done)
      .forEach((entry) => {
        this.updatePdfEntryState(entry, ExtractionStatus.Pending, 0)
      })
  }

  getOverallExtractionStatus(): OverallExtractionStatus {
    const entries = [
      ...this.state.pubMedEntries,
      ...this.state.pdfEntries,
    ].filter((entry) => entry.status !== ExtractionStatus.UploadError)

    const hasEntries = !!entries.length
    if (!hasEntries) {
      return OverallExtractionStatus.None
    }

    const hasEntriesUploading =
      this.state.isProcessingPMID ||
      !!entries.filter((entry) => entry.status === ExtractionStatus.Uploading)
        .length
    if (hasEntriesUploading) {
      return OverallExtractionStatus.Idle
    }

    const hasEntriesPending = !!entries.filter(
      (entry) =>
        entry.status === ExtractionStatus.Added ||
        entry.status === ExtractionStatus.Pending
    ).length
    if (hasEntriesPending) {
      return OverallExtractionStatus.Pending
    }

    const hasEntriesExtracting = !!entries.filter(
      (entry) => entry.status === ExtractionStatus.Extracting
    ).length
    if (hasEntriesExtracting) {
      return OverallExtractionStatus.Extracting
    }

    return OverallExtractionStatus.Complete
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.updatePubMedEntries(prevProps, prevState)
    this.updatePdfEntries(prevProps, prevState)
  }

  componentWillUnmount() {
    window.clearInterval(this.state.fakeProgressInterval)
  }

  render() {
    const overallExtractionStatus = this.getOverallExtractionStatus()

    const PubMedIcon = PubMedIconComponent as any
    const PdfIcon = PdfIconComponent as any
    const CheckIcon = CheckIconComponent as any
    const CrossIcon = CrossIconComponent as any

    return (
      <>
        <CompleteMessage />
        <LightBox
          data-test="extraction-modal"
          className="extraction-modal"
          open={this.props.isOpen}
          onClose={() => {
            if (overallExtractionStatus === OverallExtractionStatus.Complete) {
              useStoreExtraction.setState({
                extractionProgress: 0,
                isExtracting: false,
              })
              this.setState({
                pmidInputValue: '',
                isProcessingPMID: false,
                pdfEntries: [],
                pubMedEntries: [],
                totalToExtract: 0,
                totalExtracted: 0,
              })
            }
            this.props.onClose && this.props.onClose()
          }}
          reactModalProps={{ style: modalStyles }}
        >
          <>
            {/* Header */}
            {overallExtractionStatus === OverallExtractionStatus.Extracting ? (
              <>
                <Headline type="H1" className="text-center pb-6 block">
                  Extraction in Progress
                </Headline>
                <p className="text-center text-rich-black-lighter">
                  Extracted{' '}
                  <b>
                    {this.state.totalExtracted} / {this.state.totalToExtract}
                  </b>
                </p>
              </>
            ) : overallExtractionStatus === OverallExtractionStatus.Complete ? (
              <ExtractionResultHeadline />
            ) : (
              <>
                <Headline type="H1" className="text-center pb-6 block">
                  Add Publications to Extract Genes.
                </Headline>
                <p className="text-center text-rich-black-lighter">
                  You can add <b className="text-black">PDF files</b> and{' '}
                  <b className="text-black">PubMed IDs</b>:
                </p>
              </>
            )}

            {/* Input areas */}
            <div className="sm:flex sm:-mx-4">
              {/* PDF Input area */}
              <div className="sm:w-1/2 sm:px-4 mt-8">
                {overallExtractionStatus !==
                  OverallExtractionStatus.Extracting &&
                overallExtractionStatus !== OverallExtractionStatus.Complete ? (
                  <PDFInput
                    data-test="pdf-input"
                    className="mb-6"
                    onChange={(acceptedFiles, rejectedFiles) => {
                      // add new items to list
                      this.setState({
                        pdfEntries: [
                          ...rejectedFiles,
                          ...this.state.pdfEntries,
                          ...acceptedFiles,
                        ],
                      })
                    }}
                  />
                ) : (
                  ''
                )}

                {/* PDF list */}
                <div data-test="pdf-list">
                  {this.state.pdfEntries.map((entry, index) => (
                    <Tippy
                      key={entry.file.name + '' + entry.file.lastModified}
                      onShow={(props) => {
                        if (
                          entry.status !== ExtractionStatus.UploadError &&
                          entry.status !== ExtractionStatus.ExtractionError
                        ) {
                          return false
                        }
                      }}
                      content={
                        entry.status === ExtractionStatus.UploadError ? (
                          <div className="px-4 pt-3 pb-4">
                            <strong className="block mb-3">
                              Upload failed
                            </strong>
                            <p className="block mb-3">
                              We were unable to upload this file.
                            </p>
                            <p className="flex">
                              <span
                                className="inline-block mr-2"
                                role="img"
                                aria-label="ideas"
                              >
                                💡
                              </span>
                              <span className="font-bold">
                                Check if the file is a PDF
                                <br />
                                or if it is corrupt and try
                                <br />
                                again to upload it.
                              </span>
                            </p>
                          </div>
                        ) : (
                          <div className="px-4 pt-3 pb-4">
                            <strong className="block mb-3">
                              Extraction failed
                            </strong>
                            <p className="block mb-3">
                              We were unable to extract the
                              <br />
                              genes from this PDF document.
                            </p>
                            <p className="flex">
                              <span
                                className="inline-block mr-2"
                                role="img"
                                aria-label="ideas"
                              >
                                💡
                              </span>
                              <span className="font-bold">
                                Is this a scanned document?
                                <br />
                                Knora currently doesn’t
                                <br />
                                support scans.
                              </span>
                            </p>
                          </div>
                        )
                      }
                      placement="right"
                      appendTo={() =>
                        document.getElementsByClassName('ReactModalPortal')[0]
                      }
                      theme="light"
                      allowHTML
                      popperOptions={{
                        modifiers: [
                          {
                            name: 'flip',
                            options: {
                              fallbackPlacements: ['bottom'],
                            },
                          },
                        ],
                      }}
                    >
                      <div className="tippy-container">
                        <ExtractionBox
                          data-test={`pdf-list-item-${entry.file.name}`}
                          className="mb-4 last:mb-0"
                          icon={<PdfIcon className="h-6" />}
                          uploadProgress={entry.uploadProgress}
                          extractionProgress={entry.extractionProgress}
                          hasError={
                            entry.status === ExtractionStatus.UploadError ||
                            entry.status === ExtractionStatus.ExtractionError
                          }
                          actionItem={
                            entry.status === ExtractionStatus.Uploading ||
                            entry.status === ExtractionStatus.Extracting ? (
                              <RoundLoadingIndicator>
                                {entry.status === ExtractionStatus.Uploading
                                  ? 'Uploading...'
                                  : 'Extracting...'}
                              </RoundLoadingIndicator>
                            ) : entry.status === ExtractionStatus.Done ? (
                              <CheckIcon
                                data-test="icon-check"
                                className="stroke-current text-vibrant-green w-3 h-3"
                              />
                            ) : entry.status ===
                                ExtractionStatus.ExtractionError ||
                              (entry.status === ExtractionStatus.UploadError &&
                                (overallExtractionStatus ===
                                  OverallExtractionStatus.Extracting ||
                                  overallExtractionStatus ===
                                    OverallExtractionStatus.Complete)) ? (
                              <CrossIcon
                                data-test="icon-cross"
                                className="stroke-current text-rich-red"
                              />
                            ) : (
                              <RemoveButton
                                data-test="btn-remove"
                                onClick={() => {
                                  // remove item at given index
                                  this.setState({
                                    pdfEntries: [
                                      ...this.state.pdfEntries.slice(0, index),
                                      ...this.state.pdfEntries.slice(index + 1),
                                    ],
                                  })
                                }}
                                red={
                                  entry.status === ExtractionStatus.UploadError
                                }
                                title="Remove"
                              />
                            )
                          }
                        >
                          <div className="text-rich-black-light truncate py-2">
                            {entry.file.name}
                          </div>
                        </ExtractionBox>
                      </div>
                    </Tippy>
                  ))}
                </div>
              </div>

              {/* PubMed entries list */}
              <div className="sm:w-1/2 sm:px-4 mt-8">
                {overallExtractionStatus !==
                  OverallExtractionStatus.Extracting &&
                overallExtractionStatus !== OverallExtractionStatus.Complete ? (
                  <PMIDInput
                    data-test="pmid-input"
                    componentRef={this.pmidInputRef}
                    className="mb-6"
                    value={this.state.pmidInputValue}
                    isProcessing={this.state.isProcessingPMID}
                    onChange={(value) => {
                      this.setState({
                        pmidInputValue: value,
                      })
                    }}
                    disabled={this.state.isProcessingPMID}
                    onAdd={() => {
                      this.setState({
                        isProcessingPMID: true,
                      })
                    }}
                  />
                ) : (
                  ''
                )}

                {/* Publication list */}
                <div data-test="pmid-list">
                  {this.state.pubMedEntries.map((entry, index) => (
                    <Tippy
                      key={entry.pmid}
                      onShow={(props) => {
                        if (entry.status !== ExtractionStatus.ExtractionError) {
                          return false
                        }
                      }}
                      content={
                        <div className="px-4 pt-3 pb-4">
                          <strong className="block mb-3">
                            Extraction failed
                          </strong>
                          <p className="block mb-3">
                            We were unable to extract the
                            <br />
                            genes from this document.
                          </p>
                          <p className="flex">
                            <span
                              className="inline-block mr-2"
                              role="img"
                              aria-label="ideas"
                            >
                              💡
                            </span>
                            <span className="font-bold">
                              You can report the error to
                              <br />
                              us so we can look into it.
                            </span>
                          </p>
                        </div>
                      }
                      placement="right"
                      appendTo={() =>
                        document.getElementsByClassName('ReactModalPortal')[0]
                      }
                      theme="light"
                      allowHTML
                      popperOptions={{
                        modifiers: [
                          {
                            name: 'flip',
                            options: {
                              fallbackPlacements: ['bottom'],
                            },
                          },
                        ],
                      }}
                    >
                      <div className="tippy-container">
                        <ExtractionBox
                          data-test={`pmid-list-item-${entry.pmid}`}
                          className="mb-4 last:mb-0"
                          icon={<PubMedIcon className="h-6" />}
                          hasError={
                            entry.status === ExtractionStatus.ExtractionError
                          }
                          extractionProgress={entry.extractionProgress}
                          actionItem={
                            entry.status === ExtractionStatus.Extracting ? (
                              <RoundLoadingIndicator>
                                Extracting...
                              </RoundLoadingIndicator>
                            ) : entry.status === ExtractionStatus.Done ? (
                              <CheckIcon
                                data-test="icon-check"
                                className="stroke-current text-vibrant-green w-3 h-3"
                              />
                            ) : entry.status ===
                              ExtractionStatus.ExtractionError ? (
                              <CrossIcon
                                data-test="icon-cross"
                                className="stroke-current text-rich-red"
                              />
                            ) : (
                              <RemoveButton
                                data-test="btn-remove"
                                onClick={() => {
                                  // remove item at given index
                                  this.setState({
                                    pubMedEntries: [
                                      ...this.state.pubMedEntries.slice(
                                        0,
                                        index
                                      ),
                                      ...this.state.pubMedEntries.slice(
                                        index + 1
                                      ),
                                    ],
                                  })
                                }}
                                title="Remove"
                              />
                            )
                          }
                        >
                          <div className="text-xs text-rich-black-light truncate">
                            {entry.title}
                          </div>
                          <a
                            href={`https://pubmed.ncbi.nlm.nih.gov/${entry.pmid}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-xs text-vibrant-cyan font-bold truncate"
                          >
                            {entry.pmid}
                          </a>
                        </ExtractionBox>
                      </div>
                    </Tippy>
                  ))}
                </div>
              </div>
            </div>

            {/* Call to action buttons */}
            {overallExtractionStatus !== OverallExtractionStatus.None ? (
              <Theme themeName="vibrantCyan" className="w-full">
                {overallExtractionStatus ===
                OverallExtractionStatus.Extracting ? (
                  <>
                    <Link
                      to={'/database/'}
                      onClick={() => {
                        useStoreExtraction.setState({
                          isExtractionModalOpen: false,
                        })
                      }}
                    >
                      <Button
                        data-test="btn-hide-and-go-to-db"
                        className="w-full mt-8"
                        size="big"
                      >
                        Hide this Window and go to Database
                      </Button>
                    </Link>
                    <Theme themeName="richBlue" className="w-full">
                      <Button
                        data-test="btn-cancel-extraction"
                        disabled={
                          !this.state.extractionSessionId ||
                          this.state.isCancelingExtraction
                        }
                        onClick={this.cancelExtraction.bind(this)}
                        appearance="ghost"
                        className="w-full justify-center mt-4"
                        size="big"
                      >
                        {this.state.isCancelingExtraction ? (
                          <>
                            <RoundLoadingIndicator
                              style={{ transform: 'translateY(0.35rem)' }}
                              className="-mt-10 mr-4 inline-flex"
                            />{' '}
                            <span>Canceling Extraction</span>
                          </>
                        ) : (
                          'Cancel Extraction'
                        )}
                      </Button>
                    </Theme>
                  </>
                ) : overallExtractionStatus ===
                    OverallExtractionStatus.Pending ||
                  overallExtractionStatus === OverallExtractionStatus.Idle ? (
                  <Button
                    data-test="btn-start-extraction"
                    disabled={
                      overallExtractionStatus === OverallExtractionStatus.Idle
                    }
                    className="w-full mt-8"
                    size="big"
                    onClick={this.startExtraction.bind(this)}
                  >
                    Start Extraction
                  </Button>
                ) : (
                  <Link
                    to={'/database/'}
                    onClick={() => {
                      useStoreExtraction.setState({
                        isExtractionModalOpen: false,
                        extractionProgress: 0,
                      })
                      this.setState({
                        pmidInputValue: '',
                        isProcessingPMID: false,
                        pdfEntries: [],
                        pubMedEntries: [],
                        totalToExtract: 0,
                        totalExtracted: 0,
                      })
                    }}
                  >
                    <Button
                      data-test="btn-go-to-db"
                      className="w-full mt-8"
                      size="big"
                    >
                      Go to Database
                    </Button>
                  </Link>
                )}
              </Theme>
            ) : (
              ''
            )}
          </>
        </LightBox>
      </>
    )
  }
}

export default ExtractionModal
