import React, { ReactNode } from 'react'
import ExtractionBoxProgressBar from './ExtractionBoxProgressBar'

import './ExtractionBox.css'

interface Props {
  actionItem?: ReactNode
  children: ReactNode
  className?: string
  hasError?: boolean
  icon?: ReactNode
  uploadProgress?: number
  extractionProgress?: number
  'data-test'?: string
}

const ExtractionBox: React.FC<Props> = (props) => {
  return (
    <div
      className={`extraction-box relative ${props.className || ''}`}
      data-test={props['data-test']}
    >
      <div className="rounded shadow-md bg-white px-4 py-2 flex">
        {props.icon && (
          <div className="flex-grow-0 mr-3 flex items-center">{props.icon}</div>
        )}
        <div className="flex-grow w-4/6">{props.children}</div>
        {props.actionItem && (
          <div className="flex-grow-0 ml-3 flex items-center self-center">
            {props.actionItem}
          </div>
        )}
      </div>
      <ExtractionBoxProgressBar
        className={`extraction-box__progress-bar ${
          props.hasError ? 'bg-rich-red-dark' : 'bg-rich-blue-dark'
        }`}
        progress={props.uploadProgress}
      />
      <ExtractionBoxProgressBar
        className={`extraction-box__progress-bar ${
          props.hasError ? 'bg-rich-red-dark' : 'bg-vibrant-yellow-dark'
        }`}
        progress={props.extractionProgress}
      />
    </div>
  )
}

export default ExtractionBox
