import { API, graphqlOperation } from 'aws-amplify'
import {
  onUpdateExtractionJobSession,
  onUpdateQueuedJob,
} from '../graphql/subscriptions'
import { cancelExtractionJob as cancelExtractionJobQuery } from '../graphql/mutations'
import { queueExtractionJob as queueExtractionJobQuery } from '../graphql/mutations'
import { Observable } from 'react-use/lib/useObservable'
import { PublicationSource } from 'types/amplify/sharedTypes/publication/enums/publicationSource'
import { QueuedJob as QueuedJobInterface } from 'types/amplify/sharedTypes/publication/interfaces/queuedJob.interface'
import { JobStatus } from 'types/amplify/sharedTypes/publication/enums/jobStatus'
import { Callback } from 'types/Callback'
import { IsIn, IsString, validateOrReject } from 'class-validator'

class QueuedJob implements QueuedJobInterface {
  constructor(data: any) {
    this.type = data.type
    this.id = data.id
    this.status = data.status
    this.statusDescription = data.statusDescription || ''
    this.source = data.source
    this.fileName = data.fileName || ''
    this.pubmedId = data.pubmedId || ''
    this.sessionId = data.sessionId
    this.owner = data.owner
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
  }

  type: string

  @IsString()
  id: string

  status: JobStatus

  @IsString()
  statusDescription: string

  @IsIn(['PDF', 'PUBMED'])
  source: PublicationSource

  @IsString()
  fileName: string

  @IsString()
  pubmedId: string

  @IsString()
  sessionId: string

  @IsString()
  owner: string

  @IsString()
  createdAt: string

  @IsString()
  updatedAt: string
}

export async function subscribeToQueuedJob(
  params: { sessionId: string },
  callback: Callback<QueuedJobInterface>
) {
  const subscription = (await API.graphql(
    graphqlOperation(onUpdateQueuedJob, {
      sessionId: params.sessionId,
    })
  )) as Observable<any>
  subscription.subscribe({
    next: ({ value }: any) => {
      const queuedJob = new QueuedJob(value.data.onUpdateQueuedJob)
      validateOrReject(queuedJob)
        .then(() => {
          callback(queuedJob as QueuedJobInterface)
        })
        .catch((err) => callback(undefined, err))
    },
  } as any)
}

export function unsubscibeFromQueuedJob(queuedJob: {
  unsubscribe: () => void
}) {
  queuedJob.unsubscribe()
}

export async function subscribeToExtractionSession(
  params: { owner: string },
  callback: ({ provider, value }: any) => void
) {
  const subscription = (await API.graphql(
    graphqlOperation(onUpdateExtractionJobSession, {
      owner: params.owner,
    })
  )) as Observable<any>
  return subscription.subscribe({
    next: callback,
  } as any)
}

export function unsubscibeFromExtractionSession(extractionSession: {
  unsubscribe: () => void
}) {
  extractionSession.unsubscribe()
}

interface QueueExtrationJobParams {
  source: PublicationSource
}

interface QueueExtrationJobParamsPDF extends QueueExtrationJobParams {
  fileName: string
}

interface QueueExtrationJobParamsPubmed extends QueueExtrationJobParams {
  pubmedId: string
}

export async function queueExtractionJob(params: {
  jobs: (QueueExtrationJobParamsPDF | QueueExtrationJobParamsPubmed)[]
}) {
  const extractionSessionId = ((await API.graphql({
    query: queueExtractionJobQuery,
    variables: {
      jobs: params.jobs,
    },
  })) as any).data.queueExtractionJob.id
  return extractionSessionId
}

export async function cancelExtractionJob(params: { sessionId: string }) {
  await API.graphql({
    query: cancelExtractionJobQuery,
    variables: {
      sessionId: params.sessionId,
    },
  })
}
