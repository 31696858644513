import React from 'react'

interface Props {
  className?: string
  progress?: number
}

const ExtractionBoxProgressBar: React.FC<Props> = ({ className, progress }) => {
  return (
    <div className="absolute bottom-0 inset-x-0 h-1 rounded-b-lg overflow-hidden">
      <div
        className={`absolute bottom-0 left-0 h-full w-full ${className || ''}`}
        style={{ transform: `translateX(-${(1 - (progress || 0)) * 100}%)` }}
      ></div>
    </div>
  )
}

export default ExtractionBoxProgressBar
