import ReactGA from 'react-ga'
import { getCookie, setCookie } from 'utils/cookie'
import { isStaging, isProd } from 'utils/env'

let trackingID = 'UA-000000000-1'
if (isStaging) {
  trackingID = 'UA-189007777-1'
} else if (isProd) {
  trackingID = 'UA-51006100-31'
}

export function initTracking(): void {
  const dnt =
    navigator.doNotTrack || window.doNotTrack || (navigator as any).msDoNotTrack
  const hasOptedOutOfTracking =
    dnt === '1' || dnt === 'yes' || getCookie('ga-optout')

  if (hasOptedOutOfTracking) {
    ;(window as any)[`ga-disable-${trackingID}`] = true
  }

  ReactGA.initialize(trackingID, {
    // debug: !isProd && !isStaging,
  })
  ReactGA.set({ anonymizeIp: true })
}

export function optOutOfTracking() {
  setCookie('ga-optout')
  ;(window as any)[`ga-disable-${trackingID}`] = true
}
