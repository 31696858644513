import create from 'zustand'

type State = {
  isExtractionModalOpen: boolean
  setIsAddPMIDsModalOpen: (isOpen: boolean) => void
  hasErrors: boolean
  setHasErrors: (hasErrors: boolean) => void
  isExtracting: boolean
  setIsExtracting: (isExtracting: boolean) => void
  extractionProgress: number
  setExtractionProgress: (progress: number) => void
}
export const useStoreExtraction = create<State>((set) => ({
  isExtractionModalOpen: false,
  setIsAddPMIDsModalOpen(isOpen: boolean) {
    set((state) => ({ isExtractionModalOpen: isOpen }))
  },
  hasErrors: false,
  setHasErrors(hasErrors: boolean) {
    set((state) => ({ hasErrors }))
  },
  isExtracting: false,
  setIsExtracting(isExtracting: boolean) {
    set((state) => ({ isExtracting }))
  },
  extractionProgress: 0,
  setExtractionProgress(progress: number) {
    set((state) => ({ extractionProgress: progress }))
  },
}))
