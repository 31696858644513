import { SortOption, SortOption as SortOptionGenes } from 'types/GeneList'
import { ModelSortDirection } from 'types/amplify/sharedTypes/core/enums/modelSortDirection.enum'
import { ListGenesByPublicationIdSort } from 'types/amplify/sharedTypes/gene/enums/listGenesByPublicationIdSort.enum'

export function getGenesQuery(sortOption: SortOptionGenes) {
  let sort
  let sortDirection

  switch (sortOption) {
    case SortOption.bySymbolAZ:
      sort = ListGenesByPublicationIdSort.BY_SYMBOL
      sortDirection = ModelSortDirection.ASC
      break
    case SortOption.bySymbolZA:
      sort = ListGenesByPublicationIdSort.BY_SYMBOL
      sortDirection = ModelSortDirection.DESC
      break
    case SortOption.byNameAZ:
      sort = ListGenesByPublicationIdSort.BY_NAME
      sortDirection = ModelSortDirection.ASC
      break
    case SortOption.byNameZA:
      sort = ListGenesByPublicationIdSort.BY_NAME
      sortDirection = ModelSortDirection.DESC
      break
    case SortOption.byOrganismAZ:
      sort = ListGenesByPublicationIdSort.BY_ORGANISM
      sortDirection = ModelSortDirection.ASC
      break
    case SortOption.byOrganismZA:
      sort = ListGenesByPublicationIdSort.BY_ORGANISM
      sortDirection = ModelSortDirection.DESC
      break
  }
  return /* GraphQL */ `
    query GetGenes($id: ID!, $paginationToken: String, $limit: Int, $query: String) {
      listGenesByPublicationId(publicationId: $id, nextToken: $paginationToken, limit: $limit, sort: ${sort}, sortDirection: ${sortDirection}, query: $query) {
        items {
          id
          publicationId
          ncbiId
          name
          symbol
          organism
          synonyms
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `
}
