import React, { ReactNode } from 'react'

import './RoundLoadingIndicator.css'

interface Props {
  className?: string
  style?: React.CSSProperties
  children?: ReactNode
}

const RoundLoadingIndicator: React.FC<Props> = ({
  children,
  className,
  style,
}) => {
  const cn = `round-loading-indicator ${className || ''}`
  const st = { ...(style || {}) }
  return (
    <div className={cn} style={st}>
      <svg
        className="round-loading-indicator__icon"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11.5"
          cy="11.5"
          r="9.5"
          stroke="#BFBFBF"
          strokeWidth="4"
          strokeDasharray="2.55 5"
        >
          {children && <title>{children}</title>}
        </circle>
      </svg>
    </div>
  )
}

export default RoundLoadingIndicator
