/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listPublications = /* GraphQL */ `
  query ListPublications(
    $limit: Int
    $nextToken: String
    $query: String
    $sort: ListPublicationsSort
    $sortDirection: ModelSortDirection
  ) {
    listPublications(
      limit: $limit
      nextToken: $nextToken
      query: $query
      sort: $sort
      sortDirection: $sortDirection
    ) {
      items {
        type
        id
        owner
        title
        titleSort
        genesCount
        pubmedIdOrFilename
        pubmedId
        source
        search
        createdAt
        updatedAt
        genes {
          items {
            type
            id
            owner
            publicationId
            publicationIdOwner
            ncbiId
            name
            nameSort
            symbol
            symbolSort
            organism
            organismSort
            synonyms
            search
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listGenesByPublicationId = /* GraphQL */ `
  query ListGenesByPublicationId(
    $publicationId: ID!
    $limit: Int
    $nextToken: String
    $query: String
    $sort: ListGenesByPublicationIdSort
    $sortDirection: ModelSortDirection
  ) {
    listGenesByPublicationId(
      publicationId: $publicationId
      limit: $limit
      nextToken: $nextToken
      query: $query
      sort: $sort
      sortDirection: $sortDirection
    ) {
      items {
        type
        id
        owner
        publicationId
        publicationIdOwner
        ncbiId
        name
        nameSort
        symbol
        symbolSort
        organism
        organismSort
        synonyms
        search
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExternalPublication = /* GraphQL */ `
  query GetExternalPublication($publicationId: String!) {
    getExternalPublication(publicationId: $publicationId) {
      id
      title
      source
      createdAt
      updatedAt
    }
  }
`;
export const getQueuedJob = /* GraphQL */ `
  query GetQueuedJob($id: ID!) {
    getQueuedJob(id: $id) {
      type
      id
      owner
      status
      statusDescription
      source
      fileName
      fileKey
      pubmedId
      sessionId
      createdAt
      updatedAt
    }
  }
`;
export const listQueuedJobs = /* GraphQL */ `
  query ListQueuedJobs(
    $filter: ModelQueuedJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueuedJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        type
        id
        owner
        status
        statusDescription
        source
        fileName
        fileKey
        pubmedId
        sessionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQueuedJobsBySessionId = /* GraphQL */ `
  query ListQueuedJobsBySessionId(
    $type: String
    $sessionId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQueuedJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueuedJobsBySessionId(
      type: $type
      sessionId: $sessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        id
        owner
        status
        statusDescription
        source
        fileName
        fileKey
        pubmedId
        sessionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExtractionJobSession = /* GraphQL */ `
  query GetExtractionJobSession($id: ID!) {
    getExtractionJobSession(id: $id) {
      type
      id
      createdAt
      updatedAt
      queuedJobs {
        items {
          type
          id
          owner
          status
          statusDescription
          source
          fileName
          fileKey
          pubmedId
          sessionId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
    }
  }
`;
export const listExtractionJobSessions = /* GraphQL */ `
  query ListExtractionJobSessions(
    $filter: ModelExtractionJobSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtractionJobSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        id
        createdAt
        updatedAt
        queuedJobs {
          items {
            type
            id
            owner
            status
            statusDescription
            source
            fileName
            fileKey
            pubmedId
            sessionId
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getGene = /* GraphQL */ `
  query GetGene($id: ID!) {
    getGene(id: $id) {
      type
      id
      owner
      publicationId
      publicationIdOwner
      ncbiId
      name
      nameSort
      symbol
      symbolSort
      organism
      organismSort
      synonyms
      search
      createdAt
      updatedAt
    }
  }
`;
export const getPublication = /* GraphQL */ `
  query GetPublication($id: ID!) {
    getPublication(id: $id) {
      type
      id
      owner
      title
      titleSort
      genesCount
      pubmedIdOrFilename
      pubmedId
      source
      search
      createdAt
      updatedAt
      genes {
        items {
          type
          id
          owner
          publicationId
          publicationIdOwner
          ncbiId
          name
          nameSort
          symbol
          symbolSort
          organism
          organismSort
          synonyms
          search
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
