export enum ViewOption {
  asList = 'asList',
  asGallery = 'asGallery',
}

export enum SortOption {
  byTitleAZ = 'byTitleAZ',
  byTitleZA = 'byTitleZA',
  bySourceAZ = 'bySourceAZ',
  bySourceZA = 'bySourceZA',
  byDateMostRecentFirst = 'byDateMostRecentFirst',
  byDateMostRecentLast = 'byDateMostRecentLast',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
