import React from 'react'
import ExtractionModal from 'components/ExtractionModal/ExtractionModal'
import { useStoreExtraction } from 'store/extraction'
import { useUser } from '../../UserContext'

const ExtractionModalWrapper: React.FC = () => {
  const { user } = useUser()

  const isAddPMIDsModalOpen = useStoreExtraction(
    (state) => state.isExtractionModalOpen
  )
  const setIsAddPMIDsModalOpen = useStoreExtraction(
    (state) => state.setIsAddPMIDsModalOpen
  )
  const closeAddPMIDsModal = () => {
    setIsAddPMIDsModalOpen(false)
  }
  const isExtracting = useStoreExtraction((state) => state.isExtracting)
  const extractionProgress = useStoreExtraction(
    (state) => state.extractionProgress
  )

  return (
    <>
      {user && (
        <ExtractionModal
          isOpen={isAddPMIDsModalOpen}
          isExtracting={isExtracting}
          extractionProgress={extractionProgress}
          onClose={closeAddPMIDsModal}
          owner={user.username}
        />
      )}
    </>
  )
}

export default ExtractionModalWrapper
