import React, { createContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import loadable from '@loadable/component'
import { Auth, Hub } from 'aws-amplify'
import awsconfig from './aws-exports'
import useIsClient from 'hooks/useIsClient'
import { User } from 'types/User'

if (typeof window !== 'undefined') {
  awsconfig.oauth.redirectSignIn = `${window.location.origin}/callback/`
  awsconfig.oauth.redirectSignOut = `${window.location.origin}/`
}

const UserContext = createContext<{
  user?: any
  isAuthenticating: boolean
}>({
  user: null,
  isAuthenticating: true,
})

const authWhitelist = ['/', '/privacy/', '/terms/', '/imprint/']

export function UserContextProvider(props: any): JSX.Element {
  const [isAmplifyConfigured, setIsAmplifyConfigured] = useState<boolean>(false)
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true)
  const [user, setUser] = useState<User | null>()
  const { isClient } = useIsClient()

  const pathBeforeSignIn =
    isClient && window.localStorage.getItem('pathBeforeSignIn')
  const needsSignIn =
    isClient &&
    (!authWhitelist.includes(window.location.pathname) ||
      (window.location.pathname === '/' && pathBeforeSignIn))

  useEffect(() => {
    if (!needsSignIn) return
    loadable
      .lib(() => import('aws-amplify'))
      .load()
      .then((module) => {
        ;(module as any).default.configure(awsconfig)
        setIsAmplifyConfigured(true)
      })
      .catch((err) => {
        throw err
      })
  }, [needsSignIn])

  useEffect(() => {
    if (!needsSignIn) return
    if (!isAmplifyConfigured) return
    if (!isAuthenticating) return

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn': {
          setUser({ ...data })
          // redirect user to previously saved path
          window.localStorage.removeItem('pathBeforeSignIn')
          if (pathBeforeSignIn && pathBeforeSignIn !== '/') {
            navigate(pathBeforeSignIn)
          }
          break
        }
        case 'signOut':
          setUser(null)
          break
      }
    })

    setIsAuthenticating(true)
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user)
        setIsAuthenticating(false)
      })
      .catch((err) => {
        if (authWhitelist.includes(window.location.pathname)) {
          return
        }

        // save path for redirect after sign in
        if (window.location.pathname.indexOf('/callback') !== 0) {
          window.localStorage.setItem(
            'pathBeforeSignIn',
            window.location.pathname
          )
        }

        // invoke sign in page
        Auth.federatedSignIn()
      })
  }, [needsSignIn, pathBeforeSignIn, isAuthenticating, isAmplifyConfigured])

  return (
    <UserContext.Provider
      value={{
        isAuthenticating,
        user,
      }}
      {...props}
    />
  )
}

export function useUser() {
  return React.useContext(UserContext)
}
