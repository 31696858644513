import { API, Storage } from 'aws-amplify'
import { groupBy } from 'lodash'

import { getExternalPublication as getExternalPublicationQuery } from '../graphql/queries'
import { getGenesQuery } from './queries/gene'
import {
  listPublications as listPublicationsQuery,
  getPublication as getPublicationQuery,
} from './queries/publication'
import {
  deleteGenesByPublicationId,
  deletePublications as deletePublicationsQuery,
  exportAllGenesByPublicationId,
  exportAllPublications,
  exportGenes as exportGenesQuery,
  exportPublications as exportPublicationsQuery,
} from '../graphql/mutations'
import {
  IsArray,
  IsIn,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
  validateOrReject,
} from 'class-validator'
import { ExternalPublication as ExternalPublicationInterface } from 'types/amplify/sharedTypes/publication/interfaces/externalPublication.interface'
import { ExternalPublicationSource } from 'types/amplify/sharedTypes/publication/enums/externalPublicationSource'
import { PublicationWithoutRelations } from 'types/amplify/sharedTypes/publication/interfaces/publication.interface'
import { PublicationSource } from 'types/amplify/sharedTypes/publication/enums/publicationSource'
import { SortDirection, SortOption } from 'types/PublicationList'
import { SortOption as SortOptionGenes } from 'types/GeneList'
import { Gene } from 'types/amplify/sharedTypes/gene/interfaces/gene.interface'
import { ListPublicationsSort } from 'types/amplify/sharedTypes/publication/enums/listPublicationsSort.enum'

export const PUBLICATIONS_PER_PAGE = 18

class ExternalPublication implements ExternalPublicationInterface {
  constructor(data: any) {
    this.id = data.id
    this.title = data.title
    this.source = data.source
  }

  @IsString()
  id: string

  @IsString()
  title: string

  @IsIn(['PUBMED', 'UNKNOWN'])
  source: ExternalPublicationSource
}

async function typeGuardExternalPublication(data: any) {
  const externalPublication = new ExternalPublication(data)
  await validateOrReject(externalPublication)
  return externalPublication as ExternalPublicationInterface
}

export async function getExternalPublication(pmid: string) {
  const queryResult = await API.graphql({
    query: getExternalPublicationQuery,
    variables: { publicationId: pmid },
  })

  const externalPublicationData = (queryResult as any).data
    .getExternalPublication
  if (externalPublicationData.id === null) return null

  const externalPublication = await typeGuardExternalPublication(
    externalPublicationData
  )
  return externalPublication
}

class Publication implements PublicationWithoutRelations {
  constructor(data: any) {
    this.type = data.type
    this.id = data.id
    this.pubmedId = data.pubmedId
    this.title = data.title
    this.titleSort = data.titleSort
    this.source = data.source
    this.pubmedIdOrFilename =
      data.pubmedIdOrFilename?.replace('__gc__', '') || ''
    this.owner = data.owner
    this.search = data.search
    this.createdAt = data.createdAt
    this.genesCount = data.genesCount
  }

  @IsString()
  type: string

  @IsString()
  id: string

  @IsString()
  @IsOptional()
  pubmedId: string

  @IsString()
  title: string

  @IsString()
  @IsOptional()
  titleSort: string

  @IsIn(['PDF', 'PUBMED'])
  source: PublicationSource

  @IsString()
  pubmedIdOrFilename: string

  @IsString()
  owner: string

  @IsString()
  @IsOptional()
  search: string

  @IsString()
  createdAt: string

  @IsNumber()
  genesCount: number
}

function typeGuardPublication(data: any) {
  const publication = new Publication(data)
  return validateOrReject(publication).then(
    () => publication as PublicationWithoutRelations
  )
}

interface GenesInterface {
  items: Gene[]
  nextToken?: string
}

class Genes implements GenesInterface {
  constructor(data: any) {
    this.items = data?.items || []
    this.nextToken = data?.nextToken || ''
  }

  @IsArray()
  @ValidateNested({
    each: true,
  })
  items: Gene[]

  nextToken?: string
}

function typeGuardGenes(data: any) {
  const genes = new Genes(data)
  return validateOrReject(genes).then(() => genes as GenesInterface)
}

interface QueryVariables {
  sort: ListPublicationsSort
  sortDirection: SortDirection
  limit: number
  nextToken?: string
  query?: string
}
export async function listPublications(params: {
  sort: SortOption
  search?: string
  owner?: string
  limit?: number
  nextToken: string | null
}) {
  const variables: QueryVariables = {
    sort: ListPublicationsSort.BY_CREATED_AT,
    sortDirection: SortDirection.ASC,
    limit: params.limit || PUBLICATIONS_PER_PAGE,
  }
  if (params.nextToken) {
    variables.nextToken = params.nextToken
  }
  if (params.search) {
    variables.query = params.search.toLowerCase()
  }

  let groupByFn

  if (params.search) {
    variables.sort = ListPublicationsSort.BY_TITLE
    variables.sortDirection = SortDirection.ASC
  } else {
    const groupByFnByTitle = (publication: { title?: string }) =>
      (publication.title || '-')[0].toUpperCase()
    const groupByFnByCreatedAt = (publication: { createdAt?: string }) =>
      new Date(publication.createdAt || '').toDateString()

    switch (params.sort) {
      case SortOption.byTitleAZ:
        groupByFn = groupByFnByTitle
        variables.sort = ListPublicationsSort.BY_TITLE
        variables.sortDirection = SortDirection.ASC
        break
      case SortOption.byTitleZA:
        groupByFn = groupByFnByTitle
        variables.sort = ListPublicationsSort.BY_TITLE
        variables.sortDirection = SortDirection.DESC
        break
      case SortOption.byDateMostRecentFirst:
        groupByFn = groupByFnByCreatedAt
        variables.sort = ListPublicationsSort.BY_CREATED_AT
        variables.sortDirection = SortDirection.DESC
        break
      case SortOption.byDateMostRecentLast:
        groupByFn = groupByFnByCreatedAt
        variables.sort = ListPublicationsSort.BY_CREATED_AT
        variables.sortDirection = SortDirection.ASC
        break
      case SortOption.bySourceAZ:
        variables.sort = ListPublicationsSort.BY_PUBMED_ID_OR_FILENAME
        variables.sortDirection = SortDirection.ASC
        break
      case SortOption.bySourceZA:
        variables.sort = ListPublicationsSort.BY_PUBMED_ID_OR_FILENAME
        variables.sortDirection = SortDirection.DESC
        break
    }
  }

  const queryResult = await API.graphql({
    query: listPublicationsQuery,
    variables,
  })
  const publicationsData = (queryResult as any).data.listPublications.items
  const nextToken = (queryResult as any).data.listPublications.nextToken
  const publications = (await Promise.all(
    publicationsData.map(typeGuardPublication)
  )) as PublicationWithoutRelations[]

  return [groupBy(publications, groupByFn), nextToken]
}

export async function getPublication(params: { id: string }) {
  const queryResult = await API.graphql({
    query: getPublicationQuery,
    variables: {
      id: params.id,
    },
  })
  const publicationData = (queryResult as any).data.getPublication
  if (!publicationData) return null

  const publication = (await typeGuardPublication(
    publicationData
  )) as PublicationWithoutRelations

  return publication
}

export async function getGenes(params: {
  id: string
  sort: SortOptionGenes
  paginationToken: string | null
  limit: number | null
  query: string | null
}) {
  const variables: {
    id: string
    paginationToken?: string
    limit: number | null
    query: string | null
  } = {
    id: params.id,
    limit: params.limit,
    query: params.query,
  }
  if (params.paginationToken) {
    variables.paginationToken = params.paginationToken
  }
  const queryResult = await API.graphql({
    query: getGenesQuery(params.sort),
    variables,
  })

  const genesData = (queryResult as any).data[
    Object.keys((queryResult as any).data)[0]
  ]
  if (!genesData) return null

  const genes = (await typeGuardGenes(genesData)) as GenesInterface

  return genes
}

export async function getFile(fileName: string) {
  return (await Storage.get(`__gc__${fileName}`, {
    level: 'private',
    download: false,
    contentType: 'application/pdf',
    contentDisposition: 'inline',
  })) as string
}

export async function deletePublications(publicationIds: string[]) {
  const result = await API.graphql({
    query: deletePublicationsQuery,
    variables: {
      input: publicationIds.map((id) => ({
        id,
      })),
    },
  })

  const failedPublicationIds: string[] = []
  const succeededPublicationIds: string[] = []
  ;(result as {
    data: {
      deletePublications: {
        id: string
        errors?: string[]
      }[]
    }
  }).data.deletePublications.forEach((deletion) => {
    if (deletion.errors?.length) {
      failedPublicationIds.push(deletion.id)
    } else {
      succeededPublicationIds.push(deletion.id)
    }
  })
  return {
    failedPublicationIds,
    succeededPublicationIds,
  }
}

export async function deleteGenes(params: {
  publicationId: string
  geneIds: string[]
}) {
  const result = await API.graphql({
    query: deleteGenesByPublicationId,
    variables: {
      publicationId: params.publicationId,
      input: params.geneIds.map((id) => ({
        id,
      })),
    },
  })

  const failedGeneIds: string[] = []
  const succeededGeneIds: string[] = []
  ;(result as {
    data: {
      deleteGenesByPublicationId: {
        id: string
        errors?: string[]
      }[]
    }
  }).data.deleteGenesByPublicationId.forEach((deletion) => {
    if (deletion.errors?.length) {
      failedGeneIds.push(deletion.id)
    } else {
      succeededGeneIds.push(deletion.id)
    }
  })
  return {
    failedGeneIds,
    succeededGeneIds,
  }
}

export async function exportPublications(params: { publicationIds: string[] }) {
  let result
  if (params.publicationIds.length) {
    result = await API.graphql({
      query: exportPublicationsQuery,
      variables: {
        input: params.publicationIds.map((id) => ({
          id,
        })),
      },
    })
  } else {
    result = await API.graphql({
      query: exportAllPublications,
    })
  }

  const failedPublicationIds: string[] = []
  const { errors, key } = (result as {
    data: {
      [key in 'exportPublications' | 'exportAllPublications']: {
        key: string
        errors?: {
          id: string
        }[]
      }
    }
  }).data[
    params.publicationIds.length
      ? 'exportPublications'
      : 'exportAllPublications'
  ]
  if (errors?.length) {
    failedPublicationIds.push(...errors.map((error) => error.id))
  }
  return {
    failedPublicationIds,
    key,
  }
}

export async function exportGenes(params: {
  geneIds?: string[]
  publicationId?: string
}) {
  let result
  if (params.publicationId) {
    result = await API.graphql({
      query: exportAllGenesByPublicationId,
      variables: { publicationId: params.publicationId },
    })
  } else {
    if (!params.geneIds) throw new TypeError('INVALID_PARAMS')
    result = await API.graphql({
      query: exportGenesQuery,
      variables: {
        input: params.geneIds.map((id) => ({
          id,
        })),
      },
    })
  }

  const failedGeneIds: string[] = []
  const { errors, key } = (result as {
    data: {
      [key in 'exportGenes' | 'exportAllGenesByPublicationId']: {
        key: string
        errors?: {
          id: string
        }[]
      }
    }
  }).data[
    params.publicationId ? 'exportAllGenesByPublicationId' : 'exportGenes'
  ]
  if (errors?.length) {
    failedGeneIds.push(...errors.map((error) => error.id))
  }
  return {
    failedGeneIds,
    key,
  }
}
