import React from 'react'
import { useStoreExtraction } from 'store/extraction'
import { Headline } from '@liquid-design/liquid-design-react'

const ExtractionResultHeadline = () => {
  const hasExtractionErrors = useStoreExtraction((state) => state.hasErrors)
  return (
    <Headline type="H1" className="text-center pb-6 block">
      {hasExtractionErrors
        ? 'Extraction Completed with Errors'
        : 'Extraction Complete'}
    </Headline>
  )
}

export default ExtractionResultHeadline
