export const getPublication = /* GraphQL */ `
  query GetPublication($id: ID!) {
    getPublication(id: $id) {
      type
      id
      owner
      title
      genesCount
      pubmedIdOrFilename
      pubmedId
      source
      createdAt
      updatedAt
    }
  }
`

export const listPublications = /* GraphQL */ `
  query ListPublications(
    $limit: Int
    $nextToken: String
    $query: String
    $sort: ListPublicationsSort
    $sortDirection: ModelSortDirection
  ) {
    listPublications(
      limit: $limit
      nextToken: $nextToken
      query: $query
      sort: $sort
      sortDirection: $sortDirection
    ) {
      items {
        type
        id
        owner
        title
        titleSort
        genesCount
        pubmedIdOrFilename
        pubmedId
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
