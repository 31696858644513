/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deletePublications = /* GraphQL */ `
  mutation DeletePublications($input: [IdInput!]!) {
    deletePublications(input: $input) {
      id
      errors
    }
  }
`;
export const exportPublications = /* GraphQL */ `
  mutation ExportPublications($input: [IdInput!]!) {
    exportPublications(input: $input) {
      sessionId
      key
      errors {
        id
        errors
      }
    }
  }
`;
export const exportAllPublications = /* GraphQL */ `
  mutation ExportAllPublications {
    exportAllPublications {
      sessionId
      key
      errors {
        id
        errors
      }
    }
  }
`;
export const exportGenes = /* GraphQL */ `
  mutation ExportGenes($input: [IdInput!]!) {
    exportGenes(input: $input) {
      sessionId
      key
      errors {
        id
        errors
      }
    }
  }
`;
export const exportAllGenesByPublicationId = /* GraphQL */ `
  mutation ExportAllGenesByPublicationId($publicationId: ID!) {
    exportAllGenesByPublicationId(publicationId: $publicationId) {
      sessionId
      key
      errors {
        id
        errors
      }
    }
  }
`;
export const deleteGenesByPublicationId = /* GraphQL */ `
  mutation DeleteGenesByPublicationId(
    $publicationId: ID!
    $input: [IdInput!]!
  ) {
    deleteGenesByPublicationId(publicationId: $publicationId, input: $input) {
      id
      errors
    }
  }
`;
export const queueExtractionJob = /* GraphQL */ `
  mutation QueueExtractionJob($jobs: [QueueExtractionJobInput]) {
    queueExtractionJob(jobs: $jobs) {
      type
      id
      createdAt
      updatedAt
      queuedJobs {
        items {
          type
          id
          owner
          status
          statusDescription
          source
          fileName
          fileKey
          pubmedId
          sessionId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
    }
  }
`;
export const cancelExtractionJob = /* GraphQL */ `
  mutation CancelExtractionJob($sessionId: String) {
    cancelExtractionJob(sessionId: $sessionId)
  }
`;
export const createQueuedJob = /* GraphQL */ `
  mutation CreateQueuedJob(
    $input: CreateQueuedJobInput!
    $condition: ModelQueuedJobConditionInput
  ) {
    createQueuedJob(input: $input, condition: $condition) {
      type
      id
      owner
      status
      statusDescription
      source
      fileName
      fileKey
      pubmedId
      sessionId
      createdAt
      updatedAt
    }
  }
`;
export const updateQueuedJob = /* GraphQL */ `
  mutation UpdateQueuedJob(
    $input: UpdateQueuedJobInput!
    $condition: ModelQueuedJobConditionInput
  ) {
    updateQueuedJob(input: $input, condition: $condition) {
      type
      id
      owner
      status
      statusDescription
      source
      fileName
      fileKey
      pubmedId
      sessionId
      createdAt
      updatedAt
    }
  }
`;
export const deleteQueuedJob = /* GraphQL */ `
  mutation DeleteQueuedJob(
    $input: DeleteQueuedJobInput!
    $condition: ModelQueuedJobConditionInput
  ) {
    deleteQueuedJob(input: $input, condition: $condition) {
      type
      id
      owner
      status
      statusDescription
      source
      fileName
      fileKey
      pubmedId
      sessionId
      createdAt
      updatedAt
    }
  }
`;
export const createExtractionJobSession = /* GraphQL */ `
  mutation CreateExtractionJobSession(
    $input: CreateExtractionJobSessionInput!
    $condition: ModelExtractionJobSessionConditionInput
  ) {
    createExtractionJobSession(input: $input, condition: $condition) {
      type
      id
      createdAt
      updatedAt
      queuedJobs {
        items {
          type
          id
          owner
          status
          statusDescription
          source
          fileName
          fileKey
          pubmedId
          sessionId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateExtractionJobSession = /* GraphQL */ `
  mutation UpdateExtractionJobSession(
    $input: UpdateExtractionJobSessionInput!
    $condition: ModelExtractionJobSessionConditionInput
  ) {
    updateExtractionJobSession(input: $input, condition: $condition) {
      type
      id
      createdAt
      updatedAt
      queuedJobs {
        items {
          type
          id
          owner
          status
          statusDescription
          source
          fileName
          fileKey
          pubmedId
          sessionId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteExtractionJobSession = /* GraphQL */ `
  mutation DeleteExtractionJobSession(
    $input: DeleteExtractionJobSessionInput!
    $condition: ModelExtractionJobSessionConditionInput
  ) {
    deleteExtractionJobSession(input: $input, condition: $condition) {
      type
      id
      createdAt
      updatedAt
      queuedJobs {
        items {
          type
          id
          owner
          status
          statusDescription
          source
          fileName
          fileKey
          pubmedId
          sessionId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
    }
  }
`;
export const createGene = /* GraphQL */ `
  mutation CreateGene(
    $input: CreateGeneInput!
    $condition: ModelGeneConditionInput
  ) {
    createGene(input: $input, condition: $condition) {
      type
      id
      owner
      publicationId
      publicationIdOwner
      ncbiId
      name
      nameSort
      symbol
      symbolSort
      organism
      organismSort
      synonyms
      search
      createdAt
      updatedAt
    }
  }
`;
export const updateGene = /* GraphQL */ `
  mutation UpdateGene(
    $input: UpdateGeneInput!
    $condition: ModelGeneConditionInput
  ) {
    updateGene(input: $input, condition: $condition) {
      type
      id
      owner
      publicationId
      publicationIdOwner
      ncbiId
      name
      nameSort
      symbol
      symbolSort
      organism
      organismSort
      synonyms
      search
      createdAt
      updatedAt
    }
  }
`;
export const deleteGene = /* GraphQL */ `
  mutation DeleteGene(
    $input: DeleteGeneInput!
    $condition: ModelGeneConditionInput
  ) {
    deleteGene(input: $input, condition: $condition) {
      type
      id
      owner
      publicationId
      publicationIdOwner
      ncbiId
      name
      nameSort
      symbol
      symbolSort
      organism
      organismSort
      synonyms
      search
      createdAt
      updatedAt
    }
  }
`;
export const createPublication = /* GraphQL */ `
  mutation CreatePublication(
    $input: CreatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    createPublication(input: $input, condition: $condition) {
      type
      id
      owner
      title
      titleSort
      genesCount
      pubmedIdOrFilename
      pubmedId
      source
      search
      createdAt
      updatedAt
      genes {
        items {
          type
          id
          owner
          publicationId
          publicationIdOwner
          ncbiId
          name
          nameSort
          symbol
          symbolSort
          organism
          organismSort
          synonyms
          search
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePublication = /* GraphQL */ `
  mutation UpdatePublication(
    $input: UpdatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    updatePublication(input: $input, condition: $condition) {
      type
      id
      owner
      title
      titleSort
      genesCount
      pubmedIdOrFilename
      pubmedId
      source
      search
      createdAt
      updatedAt
      genes {
        items {
          type
          id
          owner
          publicationId
          publicationIdOwner
          ncbiId
          name
          nameSort
          symbol
          symbolSort
          organism
          organismSort
          synonyms
          search
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
