import React from 'react'
import { Button, TextField } from '@liquid-design/liquid-design-react'
import PubMedIconComponent from 'assets/svgs/pubmed.svg'
import PlusIconComponent from 'assets/svgs/plus.svg'
import RoundLoadingIndicator from 'components/RoundLoadingIndicator/RoundLoadingIndicator'

import './PMIDInput.css'

interface Props {
  componentRef?: React.RefObject<HTMLDivElement>
  className?: string
  disabled?: boolean
  isProcessing: boolean
  onChange: (value: string) => void
  onAdd: (pmid: string) => void
  value: string
  'data-test'?: string
}

const PMIDInput = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const isButtonEnabled = (props.value || '').trim().length > 0

  const PlusIcon = PlusIconComponent as any
  const PubMedIcon = PubMedIconComponent as any

  return (
    <div
      data-test={props['data-test']}
      ref={props.componentRef}
      className={`pmid-input rounded px-4 pt-8 pb-6 bg-sensitive-grey ${
        props.className || ''
      }`}
    >
      <PubMedIcon className="w-20 h-auto mx-auto mb-4" />
      <p className="text-rich-black-lighter text-center mb-2">
        Insert PubMed IDs down below.
      </p>
      <form className="flex child-grow relative">
        <TextField
          value={props.value}
          disabled={props.disabled}
          className="w-full"
          inputClassName="w-full pmid-input__input"
          placeholder="Enter a PMID here"
          onChange={props.onChange}
        />
        {props.isProcessing ? (
          <RoundLoadingIndicator className="pmid-input__loading-indicator absolute right-0 h-full flex items-center mr-2" />
        ) : (
          <Button
            className={`pmid-input__plus-button absolute right-0 transition-opacity ${
              isButtonEnabled ? 'opacity-100' : 'opacity-0'
            }`}
            onClick={() => {
              props.onAdd(props.value)
            }}
            disabled={!isButtonEnabled}
          >
            <PlusIcon />
          </Button>
        )}
      </form>
    </div>
  )
})

export default PMIDInput
