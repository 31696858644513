// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-callback-tsx": () => import("./../../../src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-database-tsx": () => import("./../../../src/pages/database.tsx" /* webpackChunkName: "component---src-pages-database-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-publication-tsx": () => import("./../../../src/pages/publication.tsx" /* webpackChunkName: "component---src-pages-publication-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

