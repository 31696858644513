import React, { createContext, RefObject } from 'react'
import { Notifications, Theme } from '@liquid-design/liquid-design-react'
import useIsClient from 'hooks/useIsClient'

let ref: RefObject<any>

interface NotificationsController {
  addNotification: (options: {
    text: string
    isError?: boolean
    isInfo?: boolean
    color?: string
  }) => void
}

export const NotificationsContext = createContext<{
  getNotificationsRef: () => NotificationsController
}>({
  getNotificationsRef: () => ({ addNotification: () => null }),
})

export function NotificationsContextProvider(props: any): JSX.Element {
  ref = React.useRef()
  const getNotificationsRef = React.useCallback(() => ref.current, [])

  const { isClient } = useIsClient()

  if (!isClient) return <></>

  return (
    <>
      <Theme themeName="vibrantCyan">
        <Notifications ref={ref} />
      </Theme>
      <NotificationsContext.Provider
        value={{
          getNotificationsRef,
        }}
        {...props}
      />
    </>
  )
}

export const getNotificationsRef = () => ref.current

export function useNotifications() {
  return React.useContext(NotificationsContext)
}
