/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateQueuedJob = /* GraphQL */ `
  subscription OnUpdateQueuedJob($sessionId: String!) {
    onUpdateQueuedJob(sessionId: $sessionId) {
      type
      id
      owner
      status
      statusDescription
      source
      fileName
      fileKey
      pubmedId
      sessionId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateExtractionJobSession = /* GraphQL */ `
  subscription OnCreateExtractionJobSession($owner: String) {
    onCreateExtractionJobSession(owner: $owner) {
      type
      id
      createdAt
      updatedAt
      queuedJobs {
        items {
          type
          id
          owner
          status
          statusDescription
          source
          fileName
          fileKey
          pubmedId
          sessionId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
    }
  }
`;
export const onUpdateExtractionJobSession = /* GraphQL */ `
  subscription OnUpdateExtractionJobSession($owner: String) {
    onUpdateExtractionJobSession(owner: $owner) {
      type
      id
      createdAt
      updatedAt
      queuedJobs {
        items {
          type
          id
          owner
          status
          statusDescription
          source
          fileName
          fileKey
          pubmedId
          sessionId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
    }
  }
`;
export const onDeleteExtractionJobSession = /* GraphQL */ `
  subscription OnDeleteExtractionJobSession($owner: String) {
    onDeleteExtractionJobSession(owner: $owner) {
      type
      id
      createdAt
      updatedAt
      queuedJobs {
        items {
          type
          id
          owner
          status
          statusDescription
          source
          fileName
          fileKey
          pubmedId
          sessionId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
    }
  }
`;
export const onCreateGene = /* GraphQL */ `
  subscription OnCreateGene($owner: String) {
    onCreateGene(owner: $owner) {
      type
      id
      owner
      publicationId
      publicationIdOwner
      ncbiId
      name
      nameSort
      symbol
      symbolSort
      organism
      organismSort
      synonyms
      search
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGene = /* GraphQL */ `
  subscription OnUpdateGene($owner: String) {
    onUpdateGene(owner: $owner) {
      type
      id
      owner
      publicationId
      publicationIdOwner
      ncbiId
      name
      nameSort
      symbol
      symbolSort
      organism
      organismSort
      synonyms
      search
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGene = /* GraphQL */ `
  subscription OnDeleteGene($owner: String) {
    onDeleteGene(owner: $owner) {
      type
      id
      owner
      publicationId
      publicationIdOwner
      ncbiId
      name
      nameSort
      symbol
      symbolSort
      organism
      organismSort
      synonyms
      search
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePublication = /* GraphQL */ `
  subscription OnCreatePublication($owner: String) {
    onCreatePublication(owner: $owner) {
      type
      id
      owner
      title
      titleSort
      genesCount
      pubmedIdOrFilename
      pubmedId
      source
      search
      createdAt
      updatedAt
      genes {
        items {
          type
          id
          owner
          publicationId
          publicationIdOwner
          ncbiId
          name
          nameSort
          symbol
          symbolSort
          organism
          organismSort
          synonyms
          search
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdatePublication = /* GraphQL */ `
  subscription OnUpdatePublication($owner: String) {
    onUpdatePublication(owner: $owner) {
      type
      id
      owner
      title
      titleSort
      genesCount
      pubmedIdOrFilename
      pubmedId
      source
      search
      createdAt
      updatedAt
      genes {
        items {
          type
          id
          owner
          publicationId
          publicationIdOwner
          ncbiId
          name
          nameSort
          symbol
          symbolSort
          organism
          organismSort
          synonyms
          search
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
