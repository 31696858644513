export const modalStyles = {
  content: {
    top: '48vh',
    left: '0',
    right: '0',
    transform: 'translateY(-50%)',
    margin: 'auto',
    bottom: 'auto',
    minWidth: '18rem',
    maxHeight: '80vh',
    maxWidth: 'calc(100% - 4rem)',
    width: '42.5rem',
    padding: 0,
    overflow: 'auto',
    border: 0,
  },
}
