import React from 'react'
import PDFIconComponent from 'assets/svgs/pdf.svg'
import { useDropzone, FileRejection, FileWithPath } from 'react-dropzone'
import { PDFEntry, ExtractionStatus } from '../ExtractionModal'

interface Props {
  className?: string
  onChange: (acceptedFiles: PDFEntry[], rejectedFiles: PDFEntry[]) => void
  'data-test'?: string
}

const PDFInput: React.FC<Props> = (props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    onDrop: (acceptedFiles: FileWithPath[], rejectedFiles: FileRejection[]) => {
      props.onChange(
        acceptedFiles.map((af) => ({
          file: af,
          status: ExtractionStatus.Added,
        })),
        rejectedFiles.map(
          (rf) =>
            ({
              file: rf.file,
              errors: rf.errors,
              status: ExtractionStatus.UploadError,
            } as PDFEntry)
        )
      )
    },
  })

  const PDFIcon = PDFIconComponent as any

  return (
    <>
      <div
        data-test={props['data-test']}
        {...getRootProps()}
        className={`pdf-input rounded p-4 py-8 border-2 border-dashed border-sensitive-grey-darkest text-center ${
          props.className || ''
        } ${isDragActive ? 'cursor-grabbing' : 'cursor-pointer'}`}
      >
        <input {...getInputProps()} />
        <PDFIcon className="mx-auto mb-6" />
        {isDragActive ? (
          <span>
            <span className="text-rich-blue-dark font-black block mb-1">
              Drop the files here...
            </span>
            &nbsp;
          </span>
        ) : (
          <span>
            <span className="block mb-1">
              <span className="text-rich-blue-dark font-black">Browse</span> or{' '}
              <span className="text-rich-blue-dark font-black">
                drag & drop
              </span>
            </span>
            PDF files into this area.
          </span>
        )}
      </div>
    </>
  )
}

export default PDFInput
