export enum SortOption {
  bySymbolAZ = 'bySymbolAZ',
  bySymbolZA = 'bySymbolZA',
  byNameAZ = 'byNameAZ',
  byNameZA = 'byNameZA',
  byOrganismAZ = 'byOrganismAZ',
  byOrganismZA = 'byOrganismZA',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
